import React from 'react';

export const MekongDelta = {
    "name": "Mekong Delta",
    "link": "mekong-delta",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50380435721_d4a0d7d93f_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380435721_ae64832e15_o.jpg",
    "caption": "Life on a boat",

    "brief": "The Mekong Delta is a region in Vietnam known for its floating markets and little villages laid by the river. This region attracts many tourists every year who want to hop on a boat and discover a different way of life. Here you will find a unique ecosystem, with customs and traditions very different from anywhere else in the world.",

    "description": <p>
        The Mekong Delta consists in a network of rivers in south Vietnam, close to Ho Chi Minh City. Here, the Mekong river is distributed into a series of canals that slowly flow along to the ocean, creating what is called a <i>"delta"</i>.
        The main river itself starts in the Himalayas and passes through China, Myanmar, Thailand and Cambodia before reaching Vietnam, which partly explains why the waters are so murky.
        This region occupies approximately 40.000 square kilometers, which makes it a vast region of rivers, where much of life happens in boats crossing the canals.
        <br/><br/>
        The flow of the river created a unique ecosystem within the region, filled with canals, islands and swamps.
        Human life adapted to this environment, and what you can find now is a very different lifestyle, that revolves heavily around the river and its waters.
        You will see little villages laid by the river, and people using boats as the main means of transportation. Pagodas are almost everywhere, and so are rice paddies.
        <br/><br/>
        But perhaps, the most famous attraction of this region, are its <b>floating markets</b>.
        They draw crowds of tourists every year, who want to experience this marvelous tradition of the region.
        Besides that, tourists can take on sampan rides through amazing natural tunnels.
        <br/><br/>
        The main city of the region is <b>Cần Thơ</b>. Although not much developed, tourists can find here most of the infrastructures that cater to they interests.
        The scenery on the city is excellent, and granted to satisfy anyone’s photography passion and give a taste on the exotic flavors of the local specialties.
    </p>,

    "nrDays": <p>
        While there are many day-trip options from Ho Chi Minh City to this region, we believe at least <b>2 days</b> are necessary to start exploring some of the uniqueness of this place.
        You can spend an extra day in the Cần Thơ area if you have time for it and are enjoying the lifestyle.
    </p>,

    "whenVisit": <p>
        Different from the northern part of Vietnam, the south has a warm weather all year round.
        We recommend you visit during the <b>dry season</b>, particularly on the months from <b>February to April</b>. You will get the best weather with few rainy days.
    </p>,

    "getHere": <p>
        To easiest way to get to Cần Thơ from Ho Chi Minh City is to do it by motorcycle or private ride.
        It's a little expensive but gives you more flexibility on your timings.
        You can also make the journey by bus, but you will need to changes buses a few times, some pay close attentions to the schedules..
    </p>,

    "activities": {
        "description": "Much of life on Mekong Delta happens on a boat, and getting a local guide on a sampan or other small wooden boat is almost indispensable to get to know to region. So, find you guide, get up early and get ready for a day of adventure. Here are some of the most famous activities you can do on Mekong Delta",

        "activities": [
            {
                "name": "Mỹ Tho",
                "image": "https://live.staticflickr.com/65535/50502949756_cbe8e46b6e_o.jpg",
                "description":
                    <div>
                        <p>
                            This is the most visited city at the Mekong Delta area by tourists. Here you can see its famous <b>floating markets</b> and the <b>Vinh Trang Pagoda</b>.
                            To perfect a visit to My Tho-Ben Tre, it is clear that enjoying all activities in Con Phung Tourist Attraction is indispensable.
                        </p>
                    </div>
            },
            {
                "name": "Cai Be Floating Village",
                "image": "https://live.staticflickr.com/65535/50502949731_7682bb8212_o.jpg",
                "description":
                    <div>
                        <p>
                            Cai Be is home to one of the most famous floating markets in the region, and tourists come here everyday to experience it and taste some fresh fruits and vegetables.
                            <br/><br/>
                            Its proximity with Can Tho is a plus, and getting here early in the morning is a must if you want to see all it has to offer.
                            <br/><br/>
                        </p>

                        <h4>Cai Rang Floating Market</h4>
                        <p>
                            Recognized has a National Intangible Cultural Heritage Site by the Minister of Culture, Sports and Tourism, the Cai Rang is one of the largest floating markets in the whole of Mekong Delta.
                            <br/><br/>
                            Here you can find a plethora of items to buy, from fresh fruits and vegetables, to cloths, cosmetics, gasoline and more. As with any market, make sure to visit early in the morning.
                        </p>
                    </div>
            },
            {
                "name": "Boat Trips",
                "image": "https://live.staticflickr.com/65535/50503103032_75ccc36578_o.jpg",
                "description":
                    <div>
                        <p>
                            Mekong Delta has much more to offer than floating markets. Get on a boat ride tour through the nearby countryside and learn more about the way of life on this region.
                            You can find more about this trips with <a href="https://www.tripadvisor.com/Attraction_Review-g303942-d16942661-Reviews-Realism_Tour-Can_Tho_Mekong_Delta.html" target="_blank" rel="noopener noreferrer">Realism Tour</a> and/or <a href="https://www.tripadvisor.com/Attraction_Review-g303942-d12529974-Reviews-Mekong_Experience_Travel-Can_Tho_Mekong_Delta.html" target="_blank" rel="noopener noreferrer">Mekong Experience Travel</a>.
                        </p>
                    </div>
            }
        ]
    },

    "food": {
        "description": "Despite lacking in infrastructures, the Mekong Delta offers some interesting places where you can stop by on during your boat rides to have something to eat or drink.",

        "restaurants": [
            {
                "name": "Tomato - Pad Thai",
                "description": "Tasteful food can be chosen from a great selection of dishes, supported by an excellent service.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50502232033_b057c9e571_o.jpg"
            },
            {
                "name": "Nem nuong Thanh Van",
                "description": "Small and busy restaurant, not too touristy. Good place to taste local food.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50502232013_c6924e853a_o.jpg"
            },
            {
                "name": "Sao Hom",
                "description": "With reasonable food and good local atmosphere, this place will let you taste some of the local cuisine.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50503102942_170b83152d_o.jpg"
            },
            {
                "name": "L'Escale",
                "description": "This restaurant is located on the rooftop of a boutique hotel. Lovely view and good food.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50502949536_8a358bbc9e_o.jpg"
            },
            {
                "name": "Mekong",
                "description": "This place has amazing local food and is a great place to go. It is usually full, so make sure to arrive early",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50503102887_4067e428b6_o.jpg"
            }
        ]
    },

    "stay": {
        "description": "Mekong Delta is a very large region and there are many options where one can sleep. We recommend you stay near the city of Cần Thơ, and leave here are some suggestions of good accommodations for your stay on this city.",

        "accomodations": [
            {
                "name": "Magnolia's Can Tho",
                "description": "Close to the city centre, this comfortable and cheap option is a nice place for your stay.",
                "image": "https://live.staticflickr.com/65535/50503102882_5b74a224cb_o.jpg",
                "link": "/"
            },
            {
                "name": "Kim Tho Hotel",
                "description": "Nestled in the heart of Ninh Kieu Quay, Kim Tho Hotel is close to the main facilities of the city. It offers good value for the price you pay.",
                "image": "https://live.staticflickr.com/65535/50502231853_a20e9cfd45_o.jpg",
                "link": "/"
            },
            {
                "name": "Vinpearl Hotel Can Tho",
                "description": "This is a luxurious and expensive hotel on Can Tho. With an outdoor pool and good accommodations this is a good place for a comfortable stay.",
                "image": "https://live.staticflickr.com/65535/50502949456_58802e0f19_o.jpg",
                "link": "/"
            }
        ]
    }
}
