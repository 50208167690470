import React from 'react';
import NatureGuide from '../../../../components/guides/NatureComponent';
import Footer from '../../../../components/FooterComponent';

import { MekongDelta } from '../../../../guides/countries/vietnam/mekong-delta';

function MekongDeltaPage(props) {
    return (
        <div>
            <NatureGuide nature={ MekongDelta } pathname={ props.location.pathname } urlBack="/travel-guides/asia/vietnam" />
            <Footer />
        </div>
    );
}

export default MekongDeltaPage;
